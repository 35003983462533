import React from "react"
import { Link } from "gatsby"
import css from "./PodcastHeader.module.scss"
export default props => {
  return (
    <div className={css.base}>
      <p className={css.homeLink}>
        <Link to={`/`}>← Transbooks Home</Link>
      </p>
      <div className={css.banner}>
        <Link to={`/podcast/`}>
          <img src="/assets/img/podcast.png" alt="TRANS BOOKS Podcast" />
        </Link>
      </div>
    </div>
  )
}
