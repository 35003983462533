import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/hgw/projects/transbooks/transbooks-site/src/templates/podcast-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ゲストはグラフィックデザイナーの塚田哲也さん、大日本タイポ組合員／公共交通機関座席模様蒐集家／蛇口表情蒐集家／駄洒落文泌家などさまざまな肩書と活動で活躍する塚田さんに、出展作品 8-TWELVE のお話を中心に伺いました。Google Maps を用いた「かつてセブンイレブンだった場所」の写真集、デジタルならではの写真集の表現へのチャレンジが刺激的な試みです。ぜひ、作品をお手元にご用意して聞いてみてください。 `}</p>
    <h2>{`関連リンク`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      