import React from "react"
import "../styles/base.scss"
import SEO from "../components/seo"
import PodcastHeader from "../components/PodcastHeader"

export default props => {
  const node = props.pageContext
  const children = props.children
  return (
    <div>
      <PodcastHeader />

      <div className={`l-wrap`}>
        <SEO title={`TRANS BOOKS Podcast`} />
        <div className={`podcastMain`}>
          <ul className={`podcastEpisode`}>
            <li key={node.id}>
              <h2>{node.frontmatter.title}</h2>
              <p className={`podcastEpisode__season`}>
                Season {node.frontmatter.season} / Episode{" "}
                {node.frontmatter.episodeNumber} /{node.frontmatter.duration},{" "}
                {Math.floor((node.frontmatter.size / 1024 / 1024) * 100) / 100}
                MB
              </p>
              <audio src={node.frontmatter.url} preload="none" controls></audio>
              <div className={`podcastEpisode__content`}>{children}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
